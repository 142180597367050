import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-medium text-gray-800 mb-4">404</h1>
        <p className="text-2xl font-semibold text-gray-600 mb-8">
          Page Not Found
        </p>
        <p className="text-lg text-gray-700 mb-4">
          The page you are looking for does not
          exist.
        </p>
        
      </div>
    </div>
  );
};

export default NotFoundPage;
