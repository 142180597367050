/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useWavesurfer } from '@wavesurfer/react';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';
import Playback from './Playback';
import Button from './ui/Button';
import {Decoder, tools, Reader} from 'ts-ebml';
import { Buffer } from 'buffer';
import Loader from './Loader';
window.Buffer = Buffer;

const formatTime = (numberOfSeconds) => {
  let date = new Date(0);
  date.setSeconds(numberOfSeconds);
  return date.toISOString().substring(11, 19);
};

// eslint-disable-next-line
let retryCount = 0;
let blobCount = 0;
let audioContext = null;

const Recorder = ({ setMetaType, handleAudio, handlePauseRecording, setRecordingTime, autoStart = false}) => {
  const containerRef = useRef(null);
  const audioBlobRef = useRef(null);
  const [active, setActive] = useState(autoStart);
  const [startRecording, setStartRecording] = useState(autoStart);
  // eslint-disable-next-line
  const [stateVar, setStateVar] = useState(null); // Investigate
  const [audioUrl, setAudioUrl] = useState(null);
  const [duration, setDuration] = useState(0);
  const [isRecordingDone, setIsRecordingDone] = useState(false);
  const [finalRecording, setFinalRecording] = useState(null);
  const [playBackState, setPlayBackState] = useState('pause');
  // const [clearVisible, setClearVisible] = useState(false);
  // const [clearVis, setclearVis] = useState(true);
  const [Clear, setclear] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const restartStatusRef = useRef(false);
  const [playback, setPlayback] = useState(false);

  const { wavesurfer } = useWavesurfer({
    container: containerRef,
    height: 60,
    waveColor: '#535A63',
    progressColor: '#EE772F',
    barHeight: 1,
    barWidth: 2,
    barRadius: 4,
    barGap: 4,
  });

  const recordPluginInstance = useMemo(() => {
    if (wavesurfer) {
      const pluginOptions = {
        scrollingWaveform: true,
        renderRecordedAudio: false,
      };
    
      return wavesurfer?.registerPlugin(RecordPlugin.create(pluginOptions));
    }
    return null
  }, [wavesurfer]);

  useEffect (() => {
    blobCount = 0
    retryCount = 0
  }, [])

  useEffect(()=>{
    if(finalRecording && isRecordingDone){
      handleAudio(finalRecording);
    }
    else{
      setFinalRecording(null);
    }
  },
  // eslint-disable-next-line
  [finalRecording,isRecordingDone])

  useEffect(() => {
    if (recordPluginInstance && startRecording) {
      setStartRecording(false);
      const deviceId = RecordPlugin.getAvailableAudioDevices().then((devices) => {
        return devices[0].deviceId;
      });
      setIsDisabled(true)
      setTimeout(() => {
        setIsDisabled(false)
      }, 1500)
      recordPluginInstance.startRecording({ deviceId }).then(() => {
        setActive(true);
        setPlayBackState('pause');
      });
    }
  }, [recordPluginInstance, startRecording]);

  function readAsArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => { resolve(reader.result); };
      reader.onerror = (ev) => { reject(ev.error); };
    });
  }

  function injectMetadata(blob, duration) {
    if (blob.type?.startsWith("audio/webm")) {
      const decoder = new Decoder();
      const reader = new Reader();
      reader.logging = false;
      reader.drop_default_duration = false;
      readAsArrayBuffer(blob).then((buffer) => {
        const elms = decoder.decode(buffer);
        elms.forEach((elm) => { reader.read(elm); });
        reader.stop();
        console.log("reader.duration: ", reader.duration)
        console.log("duration: ", duration)
        let refinedMetadataBuf = tools.makeMetadataSeekable(
          reader.metadatas, duration, reader.cues
        );
        let body = buffer.slice(reader.metadataSize);
        let result = new Blob([refinedMetadataBuf, body],
          {type: blob.type});
        blob = result;
        console.log('finished recording:', blob);
        setFinalRecording(blob);
      });
    }
  }

  const onRecordEnd = async (blob) => {
    if (!blob || blob.size === 0) {
      console.log("Received an empty blob on end.");
      return;
    }

    const audioUrl = URL.createObjectURL(blob);
    setAudioUrl(audioUrl);
    await timeout(100)

    console.log("duration on end: ", window.duration)
    if (blob.type?.startsWith("audio/webm")) {
      injectMetadata(blob, Math.floor(window.duration * 1000));
    } else {
      setFinalRecording(blob);
    }
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const onDataAvailable = useCallback(async (blob) => {
    if (!(restartStatusRef.current)) {
      blobCount++
      console.log(`blob: `, blob);
      console.log("Retry count: ", retryCount)
      let combinedBlob = blob
      try {
        if (!!audioBlobRef.current) {
          combinedBlob = new Blob([audioBlobRef.current, blob], { type: blob.type })
        }
        console.log("blobCount: ", blobCount)
        if ((blob.size) < 15000) {
          if (blobCount === 1) {
            audioBlobRef.current = combinedBlob
          }
          throw Error("Blob size too small")
        } else {
          audioBlobRef.current = combinedBlob
        }
        const arrayBuffer = await combinedBlob.arrayBuffer();
        if (audioContext) {
          await audioContext.close();
        }
        audioContext = new (window.AudioContext || window.webkitAudioContext)();

        await audioContext.decodeAudioData(arrayBuffer);

        const audioUrl = URL.createObjectURL(combinedBlob);
        setPlayback(true);
        setAudioUrl(audioUrl);
        retryCount = 0
      } catch (err) {
        console.log("Error while pausing:", err)
        console.log("Retrying pause")
        if (retryCount < 12) {
          retryCount++
          await timeout(80);
          recordPluginInstance.resumeRecording()
          await timeout(80);
          recordPluginInstance.pauseRecording();
        }
      }
    }
    restartStatusRef.current = false
  }, [recordPluginInstance]);

  const resetState = () => {
    restartStatusRef.current = true
    setActive(false);
    setclear(false)
    handlePauseRecording(false)
    retryCount = 0
    audioBlobRef.current = null
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, 1500)
  }

  const onStartStop = async () => {
    if (recordPluginInstance) {
      if (active) {
        // Stop recording and reset state 
        recordPluginInstance.stopRecording();
        resetState()

        const deviceId = await RecordPlugin.getAvailableAudioDevices().then((devices) => {

          return devices[0].deviceId;
        });

        await timeout(100)
        recordPluginInstance.startRecording({ deviceId }).then(() => {
          setActive(true);
          blobCount = 0
          setPlayBackState('pause');
          setPlayback(false);
          // setClearVisible(false);
          // setclearVis(true)
          setclear(false)
        });
      } else {
        // Start new recording
        const deviceId = await RecordPlugin.getAvailableAudioDevices().then((devices) => {
          return devices[0].deviceId;
        });
        recordPluginInstance.startRecording({ deviceId }).then(() => {
          setIsDisabled(true)
          setTimeout(() => {
            setIsDisabled(false)
          }, 1500)
          setActive(true);
          blobCount = 0
          setPlayBackState('pause');
          // setClearVisible(false);
          // setclearVis(true)
          setclear(false)
        });
      }
    }
  };

  const processAudio = () => {
    if (recordPluginInstance) {
      setIsRecordingDone(true);
      window.duration = duration
      recordPluginInstance.stopRecording();
    }
    setRecordingTime(Math.floor(duration));
  };

  const onPause = () => {
    console.log("D1: ", duration)
    if (recordPluginInstance) {
      if (recordPluginInstance.isPaused()) {
        setStateVar('random'); // View doesn't shift to playback component if we remove this. Investigate
        recordPluginInstance.resumeRecording();
        setPlayBackState('pause');
        setIsDisabled(true)
        setTimeout(() => {
          setIsDisabled(false)
        }, 1500)
        handlePauseRecording(false); // Audio recording
        // setClearVisible(false);
        // setclearVis(true)
        setclear(true)
        setPlayback(false);
        return;
      }

      setStateVar('random2'); // View doesn't shift to playback component if we remove this. Investigate
      retryCount = 0
      recordPluginInstance.pauseRecording();
      handlePauseRecording(true); // Audio recording
      // setClearVisible(true);
      // setclearVis(false)
      setclear(true)
    }
  };

  const handlePlaybackState = (state) => {
    setPlayBackState(state);
  };

  const handlePlaybackPlayPause = () => {
    if (playBackState === 'pause') {
      setPlayBackState('resume');
      return;
    }
    setPlayBackState('pause');
  };

  useEffect(() => {
    if (recordPluginInstance) {
      recordPluginInstance.on('record-end', onRecordEnd);
      recordPluginInstance.on('record-data-available', onDataAvailable);
      recordPluginInstance.on('record-progress', (time) => {
        setDuration(time / 1000);
      });
    }

  }, [recordPluginInstance]);

  const isPaused = playback && recordPluginInstance && (recordPluginInstance.isPaused() || !active)
  return (
    <div className="recorder-wrapper">
      <div className={`w-full wave-container ${playback ? 'hidden' : 'block'}`}>
        <div className="flex items-center">
          <div className="flex-1 border-r border-slate-500" ref={containerRef}></div>
          {recordPluginInstance && active && (
            <div className="flex-1 flex justify-end">
              <div className="flex flex-col justify-center">
                <span className="dot"></span>
              </div>
              <span className="pl-3 mr-2 min-[350px]:mr-5 min-[350px]:pl-3 min-[350px]:w-[90px]">{formatTime(duration)}</span>
            </div>
          )}
        </div>
      </div>

      <div className={`w-full ${playback && (recordPluginInstance.isPaused() || !active) ? 'block' : 'hidden'}`}>
        {recordPluginInstance && (
          <Playback playbackAudioUrl={audioUrl} playBackState={playBackState} onPlaybackStateChange={handlePlaybackState} />
        )}
      </div>

      <div className={`flex flex-row w-full justify-between gap-4 mt-6`}>
        <div className="flex flex-1 w-full">
          <Button key="pause-recording-button" onClick={onPause} disabled={isDisabled} variant={isDisabled ? 'light' : 'red'} className="w-full" width="auto">
            {
              isDisabled ?
                <Loader spninnerClassName="m-auto" variant="small" /> :
                (isPaused ? 'Continue' : 'Pause')
            }
          </Button>
        </div>

        {isPaused && (
          <div className="flex flex-1 justify-start">
            <Button onClick={onStartStop} variant="red" className="flex justify-center w-full" width="auto">
              <div className="flex justify-center items-center ">
                {/* <img src="https://cdn2.iconfinder.com/data/icons/thin-line-color-1/21/33-512.png" width="20px" height="20px" alt="clear recording icon" /> */}
                Restart
              </div>
            </Button>
          </div>
        )}

        {recordPluginInstance && !playback && (
          <div className="flex flex-1 justify-end w-full gap-4">
            <Button key="finish-recording-button" onClick={processAudio}

              variant="light" >
              Done
            </Button>
          </div>
        )}
      </div>
      {playback && Clear && recordPluginInstance && (recordPluginInstance.isPaused() || !active) && (<div className="flex flex-row w-full justify-between gap-4 mt-4">
          <div className="flex flex-1 justify-center w-full gap-4">
            <Button
              onClick={handlePlaybackPlayPause}
              variant="light"
              disabled={recordPluginInstance && !(recordPluginInstance.isPaused() || !active)}
              className={(recordPluginInstance && !(recordPluginInstance.isPaused() || !active)) ? 'hidden w-full' : 'block w-full'}
              width="auto"
            >

              <div className="flex justify-center items-center w-full h-full">
                {/* <img src={playBackState === 'pause' ? PlayIcon  : PauseIcon} alt="Play icon not found" width="15px" height="15px" /> */}
                {playBackState === 'pause' ? 'Play' : 'Pause'}
              </div>
            </Button>

          </div>
        
          <div className="flex flex-1 justify-end w-full gap-4">
            <Button key="finish-recording-button" onClick={processAudio}
              disabled={recordPluginInstance && !(recordPluginInstance.isPaused() || !active)}
              className={(recordPluginInstance && !(recordPluginInstance.isPaused() || !active)) ? 'hidden w-full' : 'block w-full'}
              variant="light"
            >
              Done
            </Button>
          </div>
        
      </div>)}
    </div>
  );
};

export default Recorder;
