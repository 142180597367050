import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getuser } from '../utils/HelperFunctions'
import Loader from './Loader'
import { setPayingCustomer, setServiceName, setTrialEndDate, setWordCount, setTemplates, setUserData } from '../redux/UserSlice'
import { toast } from 'react-toastify'

function TotalTokens() {
  const totalWords = useSelector((state) => state?.userSlice?.wordCount)
  const dispatch = useDispatch()

  useEffect(() => {
    const userId = localStorage.getItem('user_id')
    const email = localStorage.getItem('email')
    if (!userId || userId === 'undefined') {
      return
    }
    const getUserData = async () => {
      try {
        dispatch(setWordCount(null))
        const UserData = await getuser({ user_id: userId })
        
        if (UserData) {
          if (UserData.id) {
            window.Intercom("boot", {
              api_base: "https://api-iam.intercom.io",
              app_id: "etvdo6eo",
              user_id: UserData.id,
              email: email,
              name: UserData.name,
              created_at: UserData.createdAt,
            });
          }
          dispatch(setWordCount(UserData.words))
          dispatch(setServiceName(UserData.service_name))
          dispatch(setPayingCustomer(UserData.paying_customer))
          dispatch(setTrialEndDate(UserData.trial_end_date))
          dispatch(setTemplates(UserData.templates))
          dispatch(setUserData(UserData))
        }
      } catch (error) {
        console.log("User Data not found");
        toast.error("Failed to fetch user details. Please try again.");
      }
    }
    getUserData()
  }, [dispatch])

  return (
    <div className='text-center'>
      {totalWords === null ? <Loader className="mt-[24px]" /> : totalWords >= 0 && <p className='text-[#B7BECB] mt-[24px]'>{totalWords.toLocaleString('de-CH').replace(/’/g, "'")} words available</p>}
    </div>
  )
}

export default TotalTokens
