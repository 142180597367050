import React from 'react'
import { pageSize } from '../utils/constantVariables';

function Pagination({  page, setPage, total, totalPages }) {
  const first = page <= 1
  const final = page >= Math.ceil(total / pageSize)

  const handleNext = () => {
    if (!final) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (!first) {
      setPage(page - 1);
    }
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(total / pageSize);
    const currentPageGroup = Math.ceil(page / 5);
    const startPage = (currentPageGroup - 1) * 5 + 1;
    const endPage = Math.min(currentPageGroup * 5, totalPages);
    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((item) => (
      <p
        key={item}
        onClick={() => setPage(item)}
        className={`flex items-center cursor-pointer justify-center p-2 border mr-2 rounded-lg ${page === item ? 'bg-[#E5E7EC] text-black' : ''
          } hover:bg-[#E5E7EC] text-[#868C98] hover:text-black`}
      >
        <span className='pagination-number h-4 w-4 text-center	'>{item}</span>
      </p>
    ));
  };
  if (totalPages > 1) {
  return (
    <div className="flex  text-[#525866] justify-center md:justify-end items-center">
      <>
        <div className="flex">
          <p
            onClick={handlePrev}
            className={`flex items-center ${ first ? "cursor-not-allowed" : "cursor-pointer" } justify-center p-2 border mr-2 rounded-lg text-[#868C98]`}
          >
            <span className='pagination-arrow h-4 w-4 text-center	'>←</span>
          </p>
          {renderPageNumbers()}
          <p
            onClick={handleNext}
            className={`flex items-center ${ final ? "cursor-not-allowed" : "cursor-pointer" } justify-center p-2 border rounded-lg text-[#868C98]`}
          >
            <span className='pagination-arrow h-4 w-4 text-center	'>→</span>
          </p>
        </div>
      </>

    </div>
  )
  }
}

export default Pagination
