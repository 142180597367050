import React, { useEffect, useState } from 'react'
import ClipboardImage from '../images/clipboard-plus.png'
import RecordAudio from '../components/RecordAudio'
import UploadFile from '../components/UploadFile';
import { Tabs } from '../components/ui/Tabs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CardWrapper, MainCardWrapper } from '../components/ui/CardWrapper';
import { getuser } from '../utils/HelperFunctions';
import { FetchReportTemplates } from '../redux/ReportsApis';
import { setPayingCustomer, setServiceName, setTrialEndDate, setWordCount, selectUserData, setUserData } from '../redux/UserSlice';

function AddReport() {
  const [selectedTab, setSelectedTab] = useState('record')
  const [customTemplate, setCustomTemplate] = useState(null)
  const userToken = localStorage.getItem('user_session')
  const userId = localStorage.getItem('user_id')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userData = useSelector(selectUserData);
  let isDisabled = {loading: true, noWords: true};
  isDisabled.loading = userData.length === 0
  isDisabled.noWords = userData.words === 0;

  const languageList = [
    {
      "name": "Bulgarian",
      "value": "bg"
    },
    {
      "name": "Catalan",
      "value": "ca"
    },
    {
      "name": "Czech",
      "value": "cs"
    },
    {
      "name": "Danish",
      "value": "da, da-DK"
    },
    {
      "name": "Dutch",
      "value": "nl"
    },
    {
      "name": "English",
      "value": "en"
    },
    {
      "name": "Estonian",
      "value": "et"
    },
    {
      "name": "Finnish",
      "value": "fi"
    },
    {
      "name": "French",
      "value": "fr"
    },
    {
      "name": "German",
      "value": "de"
    },
    {
      "name": "German (Switzerland)",
      "value": "german (switzerland)"
    },
    {
      "name": "Greek",
      "value": "el"
    },
    {
      "name": "Hindi",
      "value": "hi"
    },
    {
      "name": "Hungarian",
      "value": "hu"
    },
    {
      "name": "Indonesian",
      "value": "id"
    },
    {
      "name": "Italian",
      "value": "it"
    },
    {
      "name": "Japanese",
      "value": "ja"
    },
    {
      "name": "Korean",
      "value": "ko"
    },
    {
      "name": "Latvian",
      "value": "lv"
    },
    {
      "name": "Lithuanian",
      "value": "lt"
    },
    {
      "name": "Malay",
      "value": "ms"
    },
    {
      "name": "Norwegian",
      "value": "no"
    },
    {
      "name": "Polish",
      "value": "pl"
    },
    {
      "name": "Portuguese",
      "value": "pt"
    },
    {
      "name": "Romanian",
      "value": "ro"
    },
    {
      "name": "Russian",
      "value": "ru"
    },
    {
      "name": "Slovak",
      "value": "sk"
    },
    {
      "name": "Spanish",
      "value": "es"
    },
    {
      "name": "Swedish",
      "value": "sv"
    },
    {
      "name": "Thai",
      "value": "th"
    },
    {
      "name": "Turkish",
      "value": "tr"
    },
    {
      "name": "Ukrainian",
      "value": "uk"
    },
    {
      "name": "Vietnamese",
      "value": "vi"
    }
  ]

  const allModelsLanguageList = [
    { "name": "English", "value": "en" },
    { "name": "German (Switzerland)", "value": "german (switzerland)" },
    { "name": "Finnish", "value": "fi" },
    { "name": "German", "value": "de" },
    { "name": "Spanish", "value": "es" },
    { "name": "French", "value": "fr" },
    { "name": "Italian", "value": "it" },
    { "name": "Portuguese", "value": "pt" },
    { "name": "Dutch", "value": "nl" },
    { "name": "Hindi", "value": "hi" },
    { "name": "Japanese", "value": "ja" },
    { "name": "Korean", "value": "ko" },
    { "name": "Polish", "value": "pl" },
    { "name": "Russian", "value": "ru" },
    { "name": "Turkish", "value": "tr" },
    { "name": "Ukrainian", "value": "uk" },
    { "name": "Vietnamese", "value": "vi" }
  ];

  const [tabs, setTabs] = useState([
    {
      name: 'Record audio',
      active: true,
      value: 'record'
    },
    {
      name: 'Upload audio',
      active: false,
      value: 'upload'
    }
  ])

  useEffect(() => {
    dispatch(FetchReportTemplates())
  }, [dispatch])

  const handleTabChange = async (tabType) => {
    let updatedTabs = []

    tabs?.forEach((tab) => {
      if (tab.name === tabType) {
        setSelectedTab(tab.value)
        updatedTabs.push({
          name: tab.name,
          active: true,
          value: tab.value
        })
      }
      else {
        updatedTabs.push({
          name: tab.name,
          active: false,
          value: tab.value
        })
      }
    })
    setTabs(updatedTabs)

  };

  useEffect(() => {
    if (!userId) {
      navigate('/')
    }
  }, [userId, navigate])


  useEffect(() => {
    try {
      const userId = localStorage.getItem('user_id')
      if (!userId || userId === 'undefined') {
        return
      }
      const getUserData = async () => {
        const UserData = await getuser({ user_id: userId })
        if (UserData) {
          dispatch(setWordCount(UserData.words))
          dispatch(setServiceName(UserData.service_name))
          dispatch(setPayingCustomer(UserData.paying_customer))
          dispatch(setTrialEndDate(UserData.trial_end_date))
          dispatch(setUserData(UserData))
          setCustomTemplate(UserData.custom_template)
        }
      }
      getUserData()
    } catch (error) {
      console.log("User Data not found");
    }

  }, [userToken, dispatch])

  const getSortedList = (languages) => {
    languages.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      } else if (nameA > nameB) {
        return 1
      } else {
        return 0;
      }
    })
    return languages
  }


  return (
    <div>
        <MainCardWrapper>
          <div className='text-center '>
            {/*  <div className='flex md:flex-row flex-col md:justify-start justify-center items-center md:gap-4'>
              <div className='flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px] mr-2'>
                <img width={'20px'} src={ClipboardImage} alt='clip board logo' />
              </div> */}
              {/* <div className="flex ">
          <div className="flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px] mr-2">
            <img width={'20px'} src={ClipboardImage} alt="clip board logo" />
          </div>
              <div className='md:text-left text-center'>
                <h1 className='font-medium text-[16px]'>Report creation</h1>
                <p className='text-[#505050] font-SuisseIntlLight mt-[4px] font-normal text-[14px]'>Record your voice or upload a pre-existing recording</p>
              </div>
            </div> */}
            <div className="hidden sm:flex justify-between flex-wrap items-center pb-[28px] border-b-[1px] border-[#E5E7EC]">
        <div className="flex">
          <div className="flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px] mr-2">
            <img width={'20px'} src={ClipboardImage} alt="clip board logo" />
          </div>
          <div className="ml-[16px] text-left">
            <h1 className="font-medium text-[16px]">Report creation</h1>
            <p className="text-[#505050] font-SuisseIntlLight font-normal mt-[4px] text-[14px]">Record your voice or upload a pre-existing recording</p>
          </div>
        </div>
     
      </div>
      <div className="flex flex-col sm:hidden">
        <div className="flex flex-col text-center items-center">
          <div className="flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px]">
            <img width={'20px'} src={ClipboardImage} alt="clip board logo" />
          </div>
          <h1 className="font-medium text-[16px] mt-[10px]">Report creation</h1>
          <p className="text-[#505050] font-SuisseIntlLight font-normal mt-[4px] text-[14px]">Record your voice or upload a pre-existing recording</p>
        </div>
        
        </div>
     
            <div className="mt-[28px] mb-[24px] ">
              <Tabs tabs={tabs} onChange={handleTabChange} />
            </div>
            <div className={`${selectedTab === 'record' ? '' : 'hidden'} `} >
              <CardWrapper>
                <RecordAudio isDisabled={isDisabled} languages={getSortedList(languageList)} customTemplate={customTemplate} allModelsLanguageList={allModelsLanguageList} />
              </CardWrapper>
            </div>
            <div className={`${selectedTab === 'upload' ? '' : 'hidden'}`} >
            <UploadFile isDisabled={isDisabled} languages={getSortedList(languageList)} customTemplate={customTemplate} allModelsLanguageList={allModelsLanguageList}/>
            </div>
          </div>
        </MainCardWrapper>
      </div>
  )
}

export default AddReport
