import React from 'react'
import NoReportIcon from '../images/file-plus.png'
import { useNavigate } from 'react-router-dom'
import Button from './ui/Button'
import { GradientBorder } from './ui/GradientBorder'

function NoReportFound() {
  const navigate = useNavigate()
  
  return (
    <div className='flex-1 rounded-2xl  flex flex-col justify-center items-center text-center'>
      <GradientBorder>
        <div className="shadow-sm bg-white rounded-full px-4 py-4">
          <img width={'24px'} src={NoReportIcon} alt='no report icon found'/>
        </div>
      </GradientBorder>
      <h1 className='text-[24px] font-medium mt-[16px]'>No reports found in this section</h1>
      <p className='text-[16px] font-SuisseIntlLight font-normal text-[#505050] mt-[4px]'>Press the button below to add a report</p>
      <div className='mt-[24px]' onClick={() => { navigate('/add-report') }}>
        <Button variant='light'>
          Add report
        </Button>
      </div>

    </div>
  )
}

export default NoReportFound
