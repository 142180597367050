import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  userData: [],
  wordCount: null,
  service_name: null,
  paying_customer: null,
  trial_end_date: null,
  templates: [], 
  loading: false
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setWordCount: (state, action) => {
      state.wordCount = action.payload
    },
    setServiceName: (state, action) => {
      state.service_name = action.payload
    },
    setPayingCustomer: (state, action) => {
      state.paying_customer = action.payload
    },
    setTrialEndDate: (state, action) => {
      state.trial_end_date = action.payload
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    }
  }
})

export const { setWordCount, setServiceName, setPayingCustomer, setTrialEndDate, setTemplates, setUserData } = userSlice.actions

export const getWordCount = (state) => state.userSlice.wordCount
export const getServiceName = (state) => state.userSlice.service_name
export const getPayingCustomer = (state) => state.userSlice.paying_customer
export const getTrialEndDate = (state) => state.userSlice.trial_end_date
export const getTemplates = (state) => state.userSlice.templates
export const selectUserData = (state) => state.userSlice.userData

export default userSlice.reducer
