import React, { useState, useEffect } from 'react'
import Logo from "../images/logotype.png"
import { useLocation, useNavigate } from 'react-router-dom'
import MenuButton, { NavMenuButton } from './ui/MenuButton'
import Button from './ui/Button'
import closeButton from '../images/SVG/close-outline.svg'
import { signOut } from 'aws-amplify/auth'
import { useSelector } from 'react-redux'
import { selectFileUploading } from '../redux/ReportsSlice'
import { useSubscription } from '../utils/SubscriptionContext'

const Header = ({ payingCustomer }) => {

  const navigate = useNavigate()
  const location = useLocation()
  const [menuClicked, setMenuClicked] = useState(false)
  const path = window.location.pathname 
  const uploading = useSelector(selectFileUploading)
  const handleNavigation = (route) => {
    if (subscriptionExpired) return;
    setMenuClicked(false)
    if (location.pathname.includes(route) && !uploading) {
      window.location.reload()
    }else{
      navigate(route)
    }
  }

  const handleLogout = async () => {
    await signOut()
    localStorage.removeItem('user_id')
    navigate('/')
  }

  const hanldeLogoClick = ()=>{
    if (!uploading) {
      navigate('/')
    }
  }

  useEffect(() => {
    if (menuClicked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [menuClicked]);

  const { daysLeft } = useSubscription();
  const isTrialEnded = daysLeft < 0;
  const subscriptionExpired = (isTrialEnded && !payingCustomer)

  const getVariant = ({ btnPath, uploading }) => {
    if (uploading && (btnPath === path)) {
      return 'selected-disabled'
    } else if (btnPath === path) {
      return 'selected'
    } else if (uploading || subscriptionExpired) {
      return 'disabled'
    } else {
      return 'default'
    }
  }

  return (
    <div className={`relative`}>
      <div className={`relative z-0 mt-5 sm:mt-[20px] mb-[4px] max-w-[764px] p-5 w-[90vw] md:w-[80vw] lg:w-[60vw] bg-white border border-gray-100 header-shadow flex justify-between gap-[4px] items-center md:rounded-[20px] sm: ${menuClicked ? 'rounded-t-[20px] rounded-b-[4px] z-20' : 'rounded-[20px]'}`}>
        <h1 className={`text-black flex min-w-[120px] font-semibold ${uploading ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={hanldeLogoClick}>
          <img width={'170px'} src={Logo} alt="logo not found" />
        </h1>
        <div className='hidden md:flex items-center flex-wrap'>
          <MenuButton className={`min-w-[100px] mx-1 ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={getVariant({ btnPath: '/add-report', uploading })} subname='+' name=' Add report' onClick={() => { handleNavigation('add-report') }} />
          <hr className='h-[16px] mx-1 border border-[#CED0D5]'/>
          <MenuButton className={`mx-1 ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} name='Reports' variant={getVariant({ btnPath: '/reports', uploading })} onClick={() => { handleNavigation('reports') }} />
          <MenuButton className={' mx-1'} name='Logout' variant={uploading ? 'disabled' : ''} onClick={handleLogout} />
        </div>
        { menuClicked && <p className='md:hidden py-[4px] px-[4px]'>
          <img onClick={ () => setMenuClicked(!menuClicked) } src={closeButton} alt="close button not found" className='hover:bg-[#F6F8FA] hover:rounded-full h-[32px] w-[32px] cursor-pointer' />
        </p> }

        { !menuClicked && <div className='flex md:hidden cursor-pointer relative z-20'>
          <Button onClick={() => { setMenuClicked(!menuClicked) }} variant='light' className='border-none hover:bg-[#F6F8FA]' size='large'>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </Button>
        </div> }
      </div>
      <div className=''>
        {menuClicked && <div className="fixed top-0 left-0 w-full h-full bg-gray-700 opacity-30 z-10 md:hidden" onClick={() => setMenuClicked(false)}></div>}
        <div className={`${menuClicked ? 'opacity-100' : 'opacity-0 hidden'} flex flex-col gap-[4px] p-[8px] md:hidden border absolute mt-[-15px] left-1/2 transform -translate-x-1/2 rounded-b-[20px] bg-white border-gray-200 nav-shadow w-[90vw] z-20`}>
          <NavMenuButton className={`rounded-[4px] text-black ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={uploading ? 'disabled' : getVariant({ btnPath: '/add-report' })} subname='+' name=' Add report' onClick={() => { handleNavigation('add-report') }} />
          <NavMenuButton className={`rounded-[4px] text-black ${subscriptionExpired ? 'cursor-not-allowed' : ''}`} variant={uploading ? 'disabled' : getVariant({ btnPath: '/reports' })} name='Reports' onClick={() => { handleNavigation('reports') }} />
          <NavMenuButton className='rounded-t-[4px] rounded-b-[12px] text-[#525866]' variant={uploading ? 'disabled' : ''} last={true} name='Logout' onClick={handleLogout} />
        </div>
      </div>

      {/* isTrialEnded && <TrialEndModal/> */}
    </div>
  )
}

export default Header
