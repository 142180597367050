import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextInput from './ui/TextInput';
import DropDown from './ui/DropDown';
import Button from './ui/Button';
import { selectAllTemplates } from '../redux/ReportsSlice';
import { getTemplates as getUserTemplates } from '../redux/UserSlice';
import { toast } from 'react-toastify';
import RecordingPopUp from './RecordingPopUp';

export default function RecordForm({ GenerateReport, recordedAudioBlob, handleGenerateReport, customTemplates, language, allModelsLanguageList, UploadNewRecording, HandleResetState }) {   
    const templates = useSelector(selectAllTemplates);
    const userTemplatesData = useSelector(getUserTemplates);
    const [userTemplates, setUserTemplates] = useState('');

    const [formState, setFormState] = useState({
        name: '',
        doctor: localStorage.getItem('doctor_name') || '',
        clinic: localStorage.getItem('clinic_name') || '',
        audioLanguage: localStorage.getItem('audio_language') || 'de',
        reportLanguage: localStorage.getItem('report_language') || 'german (switzerland)',
        comment: '',
        template: 'select_template',
    });

    const [uploadNew, setUploadNew] = useState(false);

    useEffect(() => {
        setFormState((prev) => ({ ...prev, recordedAudioBlob }));
    }, [recordedAudioBlob]);

    useEffect(() => {
        setUserTemplates(userTemplatesData);
    }, [userTemplatesData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };
    
    const handleDropDownChange = (value) => {
        setFormState((prev)=>({...prev, [value.name]:value.value}))
    }

    const handleGenerateNewReport = async (e) => {
        e.preventDefault();
        const { name, doctor, clinic, audioLanguage, reportLanguage, comment, template } = formState;

        if (!name || !doctor || !clinic || !recordedAudioBlob || !reportLanguage || !audioLanguage) {
            toast.error("Please fill in all fields before sending.");
            return;
        }
        if (!template || template === 'select_template') {
            toast.error("Please select a template.");
            return;
        }

        const isCustomTemplate = userTemplates.includes(template);
        const data = {
            patientName: name,
            doctorName: doctor,
            clinicName: clinic,
            recordedAudioBlob,
            reportLanguage,
            audioLanguage,
            comment,
            template,
            uses_custom_template: isCustomTemplate,
        };

        try {
            GenerateReport('generating');
            await handleGenerateReport(data);
        } catch (error) {
            console.error("Error generating report: ", error);
            toast.error("The report could not be created. Please try again.");
        }
    };

    const getTemplates = () => {
        const systemTemplates = [...templates].sort((a, b) => a.name.localeCompare(b.name));
        const defaultTemplates = [{ name: 'No template', value: 'no_template_template' }, ...systemTemplates];
        const additionalUserTemplates = userTemplates ? userTemplates.map(template => ({ name: template, value: template })).sort((a, b) => a.name.localeCompare(b.name)) : [];
        additionalUserTemplates.map(template => template.name = `Custom - ${template.name}`);
        return [...defaultTemplates, ...additionalUserTemplates];
    };

    const inputLanguages = allModelsLanguageList.filter(item => item.name !== "German (Switzerland)");
    const outputLanguages = language.map(item => ({ name: item.name, value: item.name.toLowerCase() }));

    return (
        <div className='w-full'>
            <form className='text-left w-full' onSubmit={handleGenerateNewReport}>
                <div className='mt-[24px] flex flex-col sm:flex-row gap-[24px]'>
                    <TextInput required name='name' label='Patient name' placeholder='Enter patient name' type='text' value={formState.name} onChange={handleChange} />
                    <TextInput required name='doctor' label='Doctor name' placeholder='Enter doctor name' type='text' value={formState.doctor} onChange={handleChange} />
                </div>
                <div className='mt-[24px] flex flex-col sm:flex-row gap-[24px]'>
                    <TextInput required name='clinic' label='Clinic name' placeholder='Enter clinic name' type='text' value={formState.clinic} onChange={handleChange} />
                    <DropDown
                        label='Template'
                        options={getTemplates()}
                        handleOnChange={(value) => { handleDropDownChange({ name: 'template', value: value }) }}
                        selectedValue={formState.template === 'select_template' ? null : formState.template}
                        defaultValue={"select_template"}
                    />
                </div>
                <div className='mt-[24px] flex flex-col sm:flex-row gap-[24px]'>
                    <div className='flex-1'>
                        <DropDown
                            label='Input language'
                            options={inputLanguages}
                            handleOnChange={(value) => { handleDropDownChange({ name: 'audioLanguage', value: value }) }}
                            selectedValue={formState.audioLanguage}
                        />
                    </div>
                    <div className='flex-1'>
                        <DropDown
                            label='Output language'
                            options={outputLanguages}
                            handleOnChange={(value) => { handleDropDownChange({ name: 'reportLanguage', value: value }) }}
                            selectedValue={formState.reportLanguage}
                        />
                    </div>
                </div>
                <div className='mt-[24px] flex flex-col text-sm gap-1 w-full'>
                    <label htmlFor="comment" className="font-medium text-[14px]">Comment</label>
                    <textarea
                        id="comment"
                        name="comment"
                        className="rounded-[10px] inputFieldShadow text-[14px] font-SuisseIntlLight font-normal text-[#868C98] border-[1px] py-2.5 px-4 border-[#E2E4E9] hover:border-[#CED0D5] focus:border-[#A6A8AD] focus:ring-0 focus:outline-none w-full resize-none"
                        rows="3"
                        maxLength='5000'
                        placeholder='Add a comment (optional)'
                        value={formState.comment}
                        onChange={handleChange}
                    />
                </div>
                <div className="sm:flex gap-[24px] mt-[24px]">
                    <Button type='submit'>
                        Generate report
                    </Button>
                </div>
            </form>
            {uploadNew && (
                <RecordingPopUp onUploadNewRecording={UploadNewRecording} setUploadNew={setUploadNew} HandleReset={HandleResetState} />
            )}
        </div>
    );
}
