import React, { useEffect, useState } from 'react';
import { useSubscription } from '../utils/SubscriptionContext';
import { useSelector } from 'react-redux';
import { getPayingCustomer } from '../redux/UserSlice';
import Button from './ui/Button';

const expireMessage = 'Your trial period has ended. Reach out to your key account manager to continue using our services.'
const buttonMessage = 'Start paid service'
const SubscriptionBar = () => {
  const { daysLeft } = useSubscription();
  const payingCustomer = useSelector(getPayingCustomer);
  const [trialMessage, setTrialMessage] = useState()
  const [expiryMessage, setExpiryMessage] = useState()
  const [buttonText, setButtonText] = useState()

  const translateMessage = async ({ message, type }) => {
    const newLanguage = await window.Weglot.getCurrentLang();
    const words = [{ t: 1, w: message }];
    const languageTo = newLanguage;
    const data = await window.Weglot.translate({ words, languageTo });

    if (type === 'trial') {
      setTrialMessage(data[0] || message)
    } else if (type === 'expiry') {
      setExpiryMessage(data[0] || message)
    } else {
      setButtonText(data[0] || message)
    }
  };


  useEffect(() => {
    if (window.Weglot) {
      translateMessage({
        message: `Your trial ends in ${daysLeft} day(s). Start paid service now to avoid any service interruptions.`,
        type: 'trial'
      });
      translateMessage({
        message: expireMessage,
        type: 'expiry'
      });
      translateMessage({
        message: buttonMessage,
        type: 'buttonText'
      });
    }

    const handleLanguageChange = () => {
      translateMessage({
        message: `Your trial ends in ${daysLeft} day(s). Start paid service now to avoid any service interruptions.`,
        type: 'trial'
      });
      translateMessage({
        message: expireMessage,
        type: 'expiry'
      });
      translateMessage({
        message: buttonMessage,
        type: 'buttonText'
      });
    };

    window.Weglot.on('languageChanged', handleLanguageChange);

    return () => {
      window.Weglot.off('languageChanged', handleLanguageChange);
    };
  }, [daysLeft]);

  if (daysLeft === null || payingCustomer === null || payingCustomer) {
    return null;
  }

  return (
    <div
      className={`w-full p-5 md:px-4 md:py-[10px] sticky top-0 left-0 z-50 flex text-center sm:flex-row sm:text-left flex-col gap-2 justify-between items-center border-b ${daysLeft > 0 ? 'bg-orange-100 text-orange-600' : 'bg-red-100 text-red-600'
        }`}

    >
      <p>
        {daysLeft > 0
          ? trialMessage
          : expiryMessage
        }
      </p>
      <div className='w-full sm:w-auto sm:min-w-[12rem] flex justify-center sm:justify-end '>
        <a
        href="mailto:hey@mpassist.ai"
          className={`  text-white text-[14px] rounded-lg w-[83vw] sm:w-auto `}
      >
          <Button variant={daysLeft > 0 ? 'trial' : 'expire'} size='subscription'>
            <p>{buttonText}</p>
        </Button>
      </a>
      </div>
    </div>
  );
};

export default SubscriptionBar;
