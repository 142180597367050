import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../components/ui/Button'
import { toast } from 'react-toastify'
import { NoPaddingCardWrapper } from '../components/ui/CardWrapper'
import { FetchReport } from '../redux/ReportsApis'
import { UpdateReportDetails } from '../utils/HelperFunctions';
import Loader from '../components/Loader';
import TextInput from '../components/ui/TextInput'
import { seletecReportLoader } from '../redux/ReportsSlice';

function EditReport() {
  const report = useSelector((state) => state?.reportsSlice?.selectedReport)
  const reportLoader = useSelector(seletecReportLoader)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userToken = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [navigated, setNavigated] = useState(false)
  const [reportDetails, setReportDetails] = useState({
    id: '',
    patient_name: '',
    doctor_name: '',
    clinic_name: '',
    comment: ''
  })
  useEffect(() => {
    if (!report.report) {
      dispatch(FetchReport({ id: report.id }))
    }
    setReportDetails({
      id: report.id,
      patient_name: report?.patient_name,
      doctor_name: report?.doctor_name,
      clinic_name: report?.clinic_name,
      comment: report?.comment
    })
  }, [dispatch, report])

  useEffect(() => {
    if (!userToken) {
      navigate('/')
    }

    //  Scroll to top of component upon every mount.
    window.scrollTo(0, 0);
  }, [userToken, navigate])


  const handleUpdateReportDetails = async () => {
    setLoading(true)
    const detailUpdated = await UpdateReportDetails({ dataToSend: reportDetails })
    dispatch(FetchReport({ id: report.id }))
    if (detailUpdated) {
      toast.success("Report updated successfully!")
    }
    setNavigated(true)
  }

  useEffect(() => {
    if (JSON.stringify(report) === '{}') {
      navigate('/reports')
    }
  }, [navigate, report])

  useEffect(() => {
    if (!reportLoader && navigated) {
      setLoading(false)
      navigate("/report")

    }
  }, [reportLoader, navigate, navigated])

  function formatTimestamp() {
    const timestamp = new Date(report?.created_at);

    const month = timestamp.getMonth();
    const year = timestamp.getFullYear();
    const day = timestamp.getDate();
    const formattedTimestamp = `${day}.${month + 1}.${year}`;
    return formattedTimestamp;
  }

  const handleIputChange = ({ key, value }) => {
    setReportDetails((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  return (
    <NoPaddingCardWrapper>
      {loading ? (
        <div className='flex justify-center items-center min-h-[30vh]'>
          <Loader />
        </div>
      ) : (
        <>
            <div className='flex md:flex-row flex-col gap-4 justify-between border-b border-b-1 border-b-[#E5E7EC] p-[20px] sm:p-[32px]'>
              <div className='flex sm:items-center flex-col sm:flex-row'>
                <div className='ml-0 mt-0 md:text-left text-center'>
                  <p className='text-black text-[16px] font-SuisseIntlLight font-semibold'>Report #{report.row_number}</p>
                  <p className='text-[#868C98] text-[12px] mt-[4px] font-SuisseIntlLight font-normal'>Uploaded: {formatTimestamp()}</p>
                </div >
              </div >
              <div className='flex sm:flex-row flex-col items-center md:justify-center '>
                <Button onClick={handleUpdateReportDetails} size='large'>
                  Save
                </Button>
              </div>
            </div >
            
            <div className='p-[20px] sm:p-[32px]'>
              <div className='flex flex-col sm:flex-row gap-[20px]'>
                <TextInput
                  name={'patientName'}
                  label={'Patient name'}
                  placeholder={'Enter patient name'}
                  value={reportDetails.patient_name}
                  onChange={(e) => { handleIputChange({ key: 'patient_name', value: e?.target?.value }) }}
                  type='text'
                />

                <TextInput
                  name={'Doctor'}
                  label={'Doctor name'}
                  placeholder={'Enter doctor name'}
                  value={reportDetails.doctor_name}
                  onChange={(e) => { handleIputChange({ key: 'doctor_name', value: e?.target?.value }) }}
                  type='text'
                />
              </div>
              <div className='mt-[20px]'>
                <TextInput
                  name={'Clinic'}
                  label={'Clinic name'}
                  placeholder={'Enter clinic name'}
                  value={reportDetails.clinic_name}
                  onChange={(e) => { handleIputChange({ key: 'clinic_name', value: e?.target?.value }) }}
                  type='text'
                />
              </div>
              <div className='mt-[20px]'>
                <TextInput
                  textArea={true}
                  name={'Comment'}
                  label={'Comment'}
                  placeholder={'Add a comment (optional)'}
                  value={reportDetails.comment}
                  onChange={(e) => { handleIputChange({ key: 'comment', value: e?.target?.value }) }}
                  type='text'
                />
              </div>
            </div>
        </>
      )
      }
    </NoPaddingCardWrapper>
  )
}

export default EditReport