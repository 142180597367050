export default function MenuButton({ name, link, variant, onClick, className, subname }) {
  const background = {
    'selected': 'bg-[#F6F8FA] text-black hover:bg-[#F9F9F9]',
    'default': 'hover:bg-[#F9F9F9]',
    'disabled': 'text-[#868C98] cursor-default',
    'selected-disabled': 'bg-[#F6F8FA] text-[#868C98]',
  }

  return (
    <button
      disabled={variant === 'disabled'}
      onClick={onClick}
      className={`py-[8px] px-[16px] text-[14px] ${className} font-medium rounded-[10px] text-[#525866]  ${background[variant]}`}
    >
      <a href={link}>
        {subname && (<span className="text-[14px] mr-[8px]">{subname}</span>)}
        
        {name}</a>
    </button>
  );
}

export function NavMenuButton({ name, link, variant, onClick, className, subname, last = false }) {
  const background = {
    'selected': 'bg-[#F6F8FA] text-black',
    'default': 'bg-white',
    'disabled': 'text-[#868C98] cursor-default',
    'selected-disabled': 'bg-[#F6F8FA] text-[#868C98]',
  }
  return (
    <button
      disabled={variant === 'disabled'}
      onClick={onClick}
      className={`h-[52px] py-[8px] px-[16px] text-[16px] relative ${className} flex items-center justify-center font-medium w-full border border-neutral-100 ${background[variant]}`}
    >
      {
        subname && 
        (<span className="text-[22px] mr-[8px]">{subname}</span>)
      }
      <a href={link} className="mt-[4px]">{name}</a>
    </button>
  );
}
