import React, { createContext, useContext, useEffect, useState } from 'react';
import { getTrialEndDate } from '../redux/UserSlice';
import { useSelector } from 'react-redux';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [daysLeft, setDaysLeft] = useState(null);
  const trialEndDate = useSelector(getTrialEndDate);

  useEffect(() => {
    const updateSubscription = () => {
      if (trialEndDate) {
        const currentDate = new Date();
        const endDate = new Date(trialEndDate);
        const daysLeft = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
        setDaysLeft(daysLeft);
      }
    };

    updateSubscription();
  }, [trialEndDate]); 

  return (
    <SubscriptionContext.Provider value={{ daysLeft }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
