import React, { useState } from 'react'
import Loader from "../Loader";
import { TimeIcon, DownloadIcon, GeneratedIcon, CheckIcon, FailedReportIcon, RetryIcon, DisabledRetryIcon } from "./SvgIcons";
import { Tooltip } from 'react-tooltip';

function formatTimestamp(inputTimestamp) {
  const timestamp = new Date(inputTimestamp);
  const options = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
  };

  const formattedTimestamp = timestamp.toLocaleString('en-US', options)
  return formattedTimestamp;
}

function TableCell({ record, handleRetry }) {
  const [loading, setLoading] = useState(false)

  const { row_number: number, patient_name: name, status, created_at: date, doctor_name: doctor, clinic_name: clinic, report_fetched, id, error, template } = record

  const icons = {
    retry: <TimeIcon disabled={!report_fetched} />,
    processing: <TimeIcon disabled={!report_fetched} />,
    generated: <GeneratedIcon />,
    'in-progress': <DownloadIcon />,
    completed: <CheckIcon />,
    failed: <FailedReportIcon disabled={!report_fetched} />,
    default: <TimeIcon disabled={!report_fetched} />,
  };

  let templateName;
  if (template) {
    const splitted = template.split('_');
    if(splitted[splitted.length - 1] === 'template') {
      splitted.pop();
    }
    templateName = splitted.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const reportStatus = (status === 'completed' && !report_fetched) ? 'failed' : status;

  const getItemState = ({ state }) => {
    if (state === 'processing' || state === 'retry' || loading) {
      return (
        <div className="text-[14px] font-SuisseIntlLight font-normal flex items-center gap-1">
          <Loader />
          <span>Processing</span>
        </div>
      )
    }
    else if (state === 'uploading' || (state === 'failed' && error === "Audio file missing")) {
      return (
        <div className="gap-1" data-tooltip-id="audio-not-available" data-tooltip-content="Audio not available">
          <button disabled className="bg-[#FAFAFA] rounded-full px-2.5 py-2.5">
            <DisabledRetryIcon />
          </button>
        </div>
      )
    }
    else if (state === 'failed') {
      return (
        <div>
          <button className="bg-[#FAFAFA] hidden sm:block hover:bg-[#F1F1F1] rounded-full px-2.5 py-2.5" onClick={() => {
            setLoading(true)
            handleRetry({ id })
            setTimeout(() => setLoading(false), 10000)
          }}>
            <RetryIcon />
          </button>
          <p className="text-[14px] flex items-center sm:hidden font-SuisseIntlLight font-normal hover:underline"
            onClick={() => {
              setLoading(true)
              handleRetry({ id })
              setTimeout(() => setLoading(false), 10000)
            }}
          > Press here to retry</p>
        </div>
      )
    }
    else if (state === 'uploading') {
      return (
        <div className="gap-1" data-tooltip-id="audio-not-available" data-tooltip-content="Audio not available">
          <button disabled className="bg-[#FAFAFA] hidden sm:block rounded-full px-2.5 py-2.5">
            <DisabledRetryIcon />
          </button>
          <p className="text-[14px] flex items-center sm:hidden font-SuisseIntlLight font-normal"> Press here to retry</p>
        </div>
      )
    }
    return (
      <div className="text-[14px] flex items-center font-SuisseIntlLight font-normal">
        { formatTimestamp(date || new Date()) }
       
      </div>
    )
  }
  return (
    <>
      <Tooltip id="audio-not-available" place="bottom" />
      <div className={`hidden sm:flex gap-4 justify-between items-center px-[12px] pt-[20px] text-[#868C98] pb-[20px] border-b-[1px] border-[#E5E7EC] hover:bg-[#FBFBFB]`}>
        <div className="flex items-center align-middle">
          {loading ? icons["processing"] : (icons[reportStatus] || icons["default"])}
          <div className="flex ml-[16px] justify-center flex-col max-w-[100px] sm:max-w-full grow ">
            <span className="text-[12px] relative font-SuisseIntlLight font-normal">
              <span className="wg-ignore">#{number} </span>
              <span className="absolute ml-[1px] mb-1 wg-ignore">&middot; </span>
              <span className="ml-[7px] wg-ignore">{doctor}, {clinic}, </span>
              <span>{templateName}</span>
            </span>
            <span className="capitalize mt-[2px] font-medium text-black wg-ignore">{name}</span>
          </div>
        </div>
        {getItemState({ state: reportStatus })}
      </div >
      <div className={`flex sm:hidden gap-4 justify-between items-center px-[12px] pt-[20px] text-[#868C98] pb-[20px] border-b-[1px] border-[#E5E7EC] hover:bg-[#FBFBFB]`} >
        <div className="flex items-center align-middle">
          {loading ? icons["processing"] : (icons[reportStatus] || icons["default"])}
          <div className="flex ml-[16px] justify-center flex-col max-w-[calc(100% - 28px)]">
          <span className="text-[12px] relative font-SuisseIntlLight font-normal wg-ignore">#{number} <span className="absolute ml-[1px] mb-1 bottom-[-1px]">.</span> <span className="ml-[7px]">{doctor}, {clinic}</span></span>
            <p className="capitalize mt-[2px] font-medium text-black overflow-hidden whitespace-nowrap wg-ignore"> {name}</p>
            <div className="mt-[2px]">
              {getItemState({ state: reportStatus })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TableCell;
