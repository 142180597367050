import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import { toast } from 'react-toastify';

let navigate;
export const setNavigate = (nav) => {
    navigate = nav;
};

export const handleLogout = async () => {
    await signOut();
    localStorage.removeItem('user_id');
    navigate('/');
};

export const fetchAuthSessionWithLogout = async () => {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    if (!accessToken) {
        if (localStorage.getItem('session_expired') !== 'true') {
            toast.error('Your session has expired. Please sign in again.');
            localStorage.setItem('session_expired', 'true');
            await handleLogout();
        }
        return;
    }
    return accessToken;
};
