import React, { useEffect, useMemo, useState } from 'react'
import userIcon from '../images/user-icon.png'
import copyIcon from '../images/copy-icon.png'
import recordingIcon from '../images/recording-02.png'
import { useDispatch, useSelector } from 'react-redux'
import ReactMarkdown from 'react-markdown';
import Notification from '../components/Notification'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NoPaddingCardWrapper } from '../components/ui/CardWrapper'
import { UpdateReportStatus, FetchReport } from '../redux/ReportsApis'
import downloadDocIcon from '../images/download-doc.png';
import loading from '../images/generating.gif';
import { getSignedUrl } from '../utils/HelperFunctions';
import { fetchAuthSessionWithLogout } from '../utils/authSession';
import Loader from '../components/Loader';
import StatusDropdown from '../components/StatusDropdown'
import { setReportUpdate } from '../redux/ReportsSlice'

function Report() {
  const report = useSelector((state) => state?.reportsSlice?.selectedReport);

  // const fileUpdating = !(useSelector(state => state?.reportsSlice?.selectedReport));
  const fileUpdating = !(report?.report_fetched);
  // const fileUpdating =!(useSelector(state => state?.reportsSlice?.updating));
  console.log(fileUpdating);

  const [showNotification, setShowNotification] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userToken = localStorage.getItem('user_id')
  const [reportStatus, setReportStatus] = useState(null)
  const [downloadingReport, setDownloadingReport] = useState(false);
  const allowedStatus = [
    {
      name: 'Generated',
      active: false,
      value: 'generated'
    },
    {
      name: 'In Progress',
      active: false,
      value: 'in-progress'
    },
    {
      name: 'Completed',
      active: false,
      value: 'completed'
    },
  ]

  useEffect(() => {
    if (!report.report) {
      dispatch(FetchReport({ id: report.id }))

    }
  }, [dispatch, report])

  useEffect(() => {
    if (!userToken) {
      navigate('/')
    }

    //  Scroll to top of component upon every mount.
    window.scrollTo(0, 0);
  }, [userToken, navigate])

  const downloadAudioFile = async () => {
    try {
      // TODO: Add meta_type to backend
      const signedUrl = await getSignedUrl({ id: report?.id, name: report?.id, type: "download", meta_type: report?.meta_type })
      window.open(signedUrl, '_blank')
    } catch (error) {
      console.log("Downloading error ", error);
      toast.error('Error while downloading the file. Please try again')
    }
  }

  useEffect(() => {
    if (JSON.stringify(report) === '{}') {
      navigate('/reports')
    }
  }, [navigate, report])

  function formatTimestamp() {
    if (!report?.created_at) {
      return ''
    }
    const timestamp = new Date(report?.created_at);
    const dateFormatter = new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'short' });
    const formattedDate = dateFormatter.format(timestamp);
    const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: false, minute: "numeric" });
    const formattedTime = timeFormatter.format(timestamp);
    return formattedDate + ', ' + formattedTime;
  }

  const handleCopyClicked = () => {
    setShowNotification(true)
    setTimeout(() => {
      setShowNotification(false);
    }, 2000);
  }

  const components = {
    h1: ({ children, ...props }) => <h1 style={{ fontSize: '2em', fontWeight: 'bold', margin: '20px 0 10px' }} {...props}>{children}</h1>,
    h2: ({ children, ...props }) => <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '15px 0 10px' }} {...props}>{children}</h2>,
    h3: ({ children, ...props }) => <h3 style={{ fontSize: '1.17em', fontWeight: 'bold', margin: '10px 0 5px' }} {...props}>{children}</h3>,
    p: ({ children, ...props }) => <p style={{ margin: '10px 0' }} {...props}>{children}</p>,
  };

  const convertMdtoDocx = async (report) => {
    try {
      if (downloadingReport) return;

      setDownloadingReport(true);
      const id = report.id;
      const accessToken = await fetchAuthSessionWithLogout();
      if (!accessToken) {
        return
      }
      const response = await fetch(`${process.env.REACT_APP_AWS_API_ENDPOINT}/md-to-docx-pandoc/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken.toString()}`,
        },
        body: JSON.stringify({})
      });
      console.log('result', response);

      if (!response.ok) {
        console.log('Error, could not fetch converted docx');
        return;
      }

      const docxDownloadLink = await response.json();
      const { downloadUrl } = docxDownloadLink
      console.log('download link,', docxDownloadLink);
      console.log('url,', downloadUrl);

      const link = document.createElement('a');
      link.download = `Report - ${report.row_number}.docx`;
      link.href = downloadUrl;

      setDownloadingReport(false);
      link.click();
    } catch (err) {
      console.log('Failed to convert report to docx, Full Error - ', err);
      setDownloadingReport(false);
    }
  }

  const handleNavigate = () => {
    navigate("/edit-report")
  }

  const handleStatusUpdate = (status) => {
    setReportStatus(status)
    dispatch(setReportUpdate(true))
    dispatch(UpdateReportStatus({ id: report?.id, status }))
  }

 const reportText =  useMemo(() => `${report?.report}\n`?.split(" \\\n")?.join("\n"), [report])

  return (
    <NoPaddingCardWrapper>
      <div className='flex md:flex-row flex-col md:gap-2 gap-2.5 justify-between border-b border-b-1 border-b-[#E5E7EC] p-[20px] sm:p-[32px]'>
        <div className='flex items-center md:justify-start justify-center flex-col sm:flex-row md:gap-4 '>
          <div className=' w-[44px] h-[44px] border border-[#E2E4E9] rounded-full flex justify-center items-center md:mr-2'>
            <img src={userIcon} alt='user icon not found' width={20} height={20} />
          </div>
          <div className='ml-0 mt-[10px] sm:mt-0 md:text-left text-center'>
            <p className='text-[#505050] inline-block text-[14px] font-SuisseIntlLight font-normal justify-center'>
              <span className="wg-ignore">#{report.row_number}</span>
              <span className="font-normal ml-[4px] mr-[1px] text-black text-[12px]">&middot;</span>
              <span className='mx-0'> {formatTimestamp()}</span>
            </p>
            <p className='text-black text-[16px] md:block hidden capitalize	mt-[4px] wg-ignore break-words'>{report.patient_name}</p>
            <p className='text-[#375DFB] text-[14px] capitalize	mt-[4px] cursor-pointer hover:underline'
              onClick={handleNavigate}>Edit report</p>
          </div>
        </div>

        <div className='flex sm:flex-row flex-col gap-2.5 md:gap-0 items-center md:justify-center '>
          <div className='flex items-center w-full justify-center'>
            <div onClick={downloadAudioFile} style={{ borderRadius: '10px' }} className='border cursor-pointer  md:w-[40px] w-1/2 h-[40px] md:mr-[10px] mr-[5px] hover:border-[#CED0D5] border-[#E2E4E9]  flex justify-center items-center'>
              <img src={recordingIcon} alt='recording icon not found' width={'20px'} height={'20px'} />
            </div>

            {!downloadingReport && <div onClick={() => convertMdtoDocx(report)} style={{ borderRadius: '10px' }} className='border cursor-pointer  md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] md:mr-[10px] mr-[5px] border-[#E2E4E9] flex justify-center items-center'>
              <img src={downloadDocIcon} alt='download icon not found' width={'20px'} height={'20px'} tooltip='Download as doc' />
            </div>}

            {downloadingReport && <div style={{ borderRadius: '10px' }} className='sm:border cursor-pointer  md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] md:mr-[10px] mr-[5px] border-[#E2E4E9] flex justify-center items-center'>
              <img src={loading} alt='loading icon not found' width={'20px'} height={'20px'} tooltip='Downloading' />
            </div>}
            <CopyToClipboard disabled={!report.report} text={!!report.report ? reportText : "N/A"} onCopy={() => { handleCopyClicked() }}>
              <div onClick={handleCopyClicked} style={{ borderRadius: '10px' }} className='border cursor-pointer  md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] border-[#E2E4E9] md:mr-[10px] flex justify-center items-center'>
                <img src={copyIcon} alt='copy icon not found' width={'20px'} height={'20px'} />
              </div>
            </CopyToClipboard>
          </div>
          <div className='sm:block hidden w-full'>
            {!!report.status && (
              <StatusDropdown tabs={allowedStatus} disable={fileUpdating} selected={reportStatus ? reportStatus?.toLowerCase() : report.status?.toLowerCase()} handler={handleStatusUpdate} />
            )}
          </div>
          <div className='sm:hidden block w-full'>
            {!!report.status && (
              <StatusDropdown tabs={allowedStatus} mobileView={true} disable={fileUpdating} selected={reportStatus ? reportStatus?.toLowerCase() : report.status?.toLowerCase()} reports={false} handler={handleStatusUpdate} />
            )}

          </div>

          {/* {!!report.status && (
            <StatusDropdown tabs={allowedStatus} disable={fileUpdating} selected={reportStatus ? reportStatus?.toLowerCase() : report.status?.toLowerCase()} handler={handleStatusUpdate} />
          )} */}
        </div>

      </div>
      {/* <div className='sm:hidden  border-b border-b-1 border-b-[#E5E7EC] p-[20px] sm:p-[32px]'>
        <div className='flex flex-col items-center text-center'>
          <div className=' w-[44px] h-[44px] border border-[#E2E4E9] rounded-full flex justify-center items-center'>
            <img src={userIcon} alt='user icon not found' width={20} height={20} />
          </div>
          <div className='mt-[10px]'>
            <p className='text-[#505050] text-[14px] font-SuisseIntlLight font-normal'>#{report.row_number} / {formatTimestamp()}</p>
            <p className='text-[#375DFB] text-[14px] capitalize	mt-[4px] wg-ignore cursor-pointer hover:underline'
              onClick={handleNavigate}>Edit report</p>
          </div>
        </div>
        <div className='mt-[10px]'>
          <div className='flex items-center justify-center'>
            <div onClick={downloadAudioFile} style={{ borderRadius: '10px' }} className='border cursor-pointer w-1/2 h-[40px] mr-[5px] hover:border-[#CED0D5] border-[#E2E4E9]  flex justify-center items-center'>
              <img src={recordingIcon} alt='recording icon not found' width={'20px'} height={'20px'} />
            </div>

            {!downloadingReport && <div onClick={() => convertMdtoDocx(report)} style={{ borderRadius: '10px' }} className='border cursor-pointer w-1/2 h-[40px] hover:border-[#CED0D5]  border-[#E2E4E9] flex justify-center items-center'>
              <img src={downloadDocIcon} alt='download icon not found' width={'20px'} height={'20px'} />
            </div>}

            {downloadingReport && <div style={{ borderRadius: '10px' }} className='border cursor-pointer w-1/2 h-[40px] hover:border-[#CED0D5]  border-[#E2E4E9] flex justify-center items-center'>
              <img src={loading} alt='loading icon not found' width={'20px'} height={'20px'} />
            </div>}

            <CopyToClipboard disabled={!report.report} text={!!report.report ? report.report : "N/A"} onCopy={() => { handleCopyClicked() }}>
              <div onClick={handleCopyClicked} style={{ borderRadius: '10px' }} className='border cursor-pointer w-1/2 h-[40px] ml-[5px] hover:border-[#CED0D5]  border-[#E2E4E9] flex justify-center items-center'>
                <img src={copyIcon} alt='copy icon not found' width={'20px'} height={'20px'} />
              </div>
            </CopyToClipboard>
          </div>
          <div className='mt-[10px] w-full'>
            {!!report.status && (
              <StatusDropdown tabs={allowedStatus} mobileView={true} disable={fileUpdating} selected={reportStatus ? reportStatus?.toLowerCase() : report.status?.toLowerCase()} reports={false} handler={handleStatusUpdate} />
            )}
          </div>
        </div>
      </div> */}
      {!report.report ?
        <div className='p-[20px] sm:p-[32px]'>
          <Loader className="w-full flex justify-center" />
        </div> :
        <>
      
          <div className='border-b border-b-1 border-b-[#E5E7EC] p-[20px] py-[10px] sm:px-[32px] sm:py-[16px] wg-ignore'>
            <ReactMarkdown components={components}>{reportText}</ReactMarkdown>
          </div>
          <div className='p-[20px] sm:p-[32px] whitespace-pre-line'>
            <h3 className="sub-heading">Comment: </h3>
            <div className="mb-[-5px] wg-ignore">{report?.comment || "N/A"}</div>
          </div>
        </>
      }
      {showNotification && <Notification text={'Copied'} color={'bg-[#1F232E]'} />}
    </NoPaddingCardWrapper>
  )
}

export default Report