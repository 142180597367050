import { useEffect, useRef } from 'react'
import { useWavesurfer } from '@wavesurfer/react'
const Playback = ({ playbackAudioUrl, playBackState = "pause", onPlaybackStateChange }) => {
  const containerRef = useRef(null)
  // const [isPlaying, setIsPlaying] = useState(isAudioPlaying)
  
const { wavesurfer } = useWavesurfer({
    container: containerRef,
    height: 60,
    waveColor: '#535A63',
    progressColor: '#EE772F',
    barHeight: 1,
    barWidth: 2,
    barRadius: 4,
    barGap: 4,
    url: playbackAudioUrl,
    dragToSeek: true,
    backend: "WebAudio"
  })

  useEffect(()=>{
    
    if(wavesurfer){
      if (playBackState === "pause" && wavesurfer.isPlaying()){
        wavesurfer.pause() 
      }
      else if (playBackState === "resume" && !wavesurfer.isPlaying()){
        wavesurfer.play() 
      }
    }
  },[playBackState, wavesurfer])

 /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(()=>{
    if(wavesurfer){
      wavesurfer.on("finish", () => {
        onPlaybackStateChange('pause')
      });
    }
  },[wavesurfer])
  /* eslint-disable react-hooks/exhaustive-deps */

  // const changePlaybackState = (state) => {
  //   onPlaybackStateChange(state)
  // }
  // const onPlayPause = () => {
  //   // wavesurfer && wavesurfer.playPause()
  //   if(isPlaying){
  //     wavesurfer.pause()
  //     setIsPlaying(false);
  //   }
  //   else{
  //     wavesurfer.play()
  //     setIsPlaying(true);
  //   }
  // }

  return (
    <>
      <div className="w-full wave-container">
        <div ref={containerRef} />
      </div>
      {/* <Button onClick={onPlayPause} variant='light'>
        <img src={isPlaying ? PauseIcon : PlayIcon} alt='Play icon not found ' width={'20px'} height={'20px'} />
      </Button> */}
    </>
  )
}

export default Playback