export function Tabs({ tabs, onChange }) {

  return (
    <div className="rounded-[10px] p-1.5 bg-[#F6F8FA] flex gap-1 text-sm w-full overflow-x-scroll sm:overflow-hidden">
      {tabs?.map((tab, index) => (
        <Tab key={index} onChange={onChange} active={tab.active} name={tab.name} />
      ))}
    </div>
  );
}

function Tab({ active, name, onChange }) {
  return (
    <button
      onClick={() => { onChange(name) }}
      className={`py-1 px-4 font-medium min-w-[150px] grow rounded-md ${
        active ? "bg-white tab-shadow" : "hover:bg-[#EEEEEE] text-[#525866]"
      }`}
    >
      {name}
    </button>
  );
}
