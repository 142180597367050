import { fetchAuthSessionWithLogout } from "./authSession";
import { toast } from "react-toastify";

export const createRecord = async ({ userId, patientName, doctorName, clinicName, reportLanguage, uses_custom_template, audioLanguage, comment = '', template, meta_type }) => {
  try {
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const dataToSend = {
      status: "uploading",
      report: "",
      tableName: tableName,
      patient_name: patientName,
      doctor_name: doctorName,
      clinic_name: clinicName,
      audio_language: audioLanguage,
      report_language: reportLanguage,
      user_id: userId,
      uses_custom_template:uses_custom_template,
      template,
      comment,
      meta_type: meta_type || "mp3",
    }

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/create-record/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    const data = await response.json()

    if (data.statusCode !== 200) {
      throw new Error(`Failed to create record. Status: ${data.statusCode}`);
    }

    return data?.documentId
  } catch (error) {
    toast.error("Failed to create record. Please try again.");
    console.error("Create Record Error: ", error);
  }
}

export const UpdateReportDetails = async ({ dataToSend }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-report/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    const data = await response.json()
    if (data.statusCode === 200) {
      return data?.id
    }
    return null
  } catch (error) {
    toast.error("Failed to update report details. Please try again.");
    console.error("Update Report Error: ", error);
  }
}

export const handleUpdateStatus = async ({ documentId, status }) => {
  try {
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const dataToSend = {
      status: status,
      tableName: tableName,
      id: documentId
    }
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-status/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    const data = await response.json()
    if (data.statusCode === 200) {
      return data?.id
    }
    return data
  }
  catch (error) {
    toast.error("Failed to update status. Please try again.");
    console.error("Update Status Error: ", error);
  }
}

export const handleUpdateError = async ({ id, error }) => {
  try{
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    let dataToSend
      dataToSend = {
        error: error,
        status: 'completed',
        tableName: tableName,
        id: id
      }

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-error/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.message
    }
    return response.message
  } catch (error) {
    toast.error("Failed to update error. Please try again.");
    console.error("Update Error Error: ", error);
  }
}

export const getuser = async ({ user_id }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/get-user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({ user_id })
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.user
    }
    return null
  } catch (error) {
    console.log("Get user error ", error);
    toast.error("Failed to fetch user details. Please try again.");
    return null
  }
}


export const getSignedUrl = async ({ id, name, type, meta_type}) => {
  try {
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    const response = await fetch(`${process.env.REACT_APP_AWS_API_ENDPOINT}/get-signed-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({
        name: name,
        type,
        meta_type: meta_type
      })
    })
    if (!response.ok) {
      throw new Error(`Signed url not found Status: ${response.status}`);
    }
    const data = await response.json()
    return data.url;
  } catch (errorInCatch) {
    handleUpdateError({ id: id, error: JSON.stringify(errorInCatch) })
    console.log("Error while updating supabase ", errorInCatch);
    toast.error("Failed to get signed URL. Please try again.");
  }
}

export const uploadToS3 = async ({ id, file, signedUrl }) => {
  try {
    console.log("Signed url is ", signedUrl);
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {  
        'Content-Type': file.type,
      }
    });
    if (response.ok) {
      console.log('File uploaded successfully');
      return { documentId: id, uploadStatus: true }
    } else {
      throw new Error(`Failed to upload file: ${response.statusText}`);
    }
  } catch (errorInCatch) {
    handleUpdateError({ id: id, error: JSON.stringify(errorInCatch) })
    console.log("Error while updating supabase ", errorInCatch);
    toast.error("Failed to upload file. Please try again.");
  }
}

export const updateServiceName = async ({ user_id, service_name }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-service/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({ user_id, service_name })
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.user
    }
    return null
  } catch (error) {
    console.log("update service name error ", error);
    toast.error("Failed to update service name. Please try again.");
    return null
  }
} 