import React, { useEffect } from 'react';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NotFoundPage from './pages/NotFound';
import MainPage from './pages/MainPage';
import AddReport from './pages/AddReport';
import Reports from './pages/Reports';
import Report from './pages/Report';
import useWebSocket from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { FetchBackendReports } from './redux/ReportsApis';
import { UpdateReport, setLatestPayload } from './redux/ReportsSlice';
import EditReport from './pages/EditReport';
import { pageSize } from './utils/constantVariables';
import { SubscriptionProvider } from './utils/SubscriptionContext';
import { setPayingCustomer, getPayingCustomer } from './redux/UserSlice';
import Layout from './Layout';
import { setNavigate } from './utils/authSession';

const WS_URL = process.env.REACT_APP_WS_URL

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = localStorage.getItem('user_id') || "";

  const payingCustomer = useSelector(getPayingCustomer);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    window.Intercom("update");
  }, [location]);

  useEffect(() => {
    // Clear the previous paying customer state
    dispatch(setPayingCustomer(null));

    // Simulate fetching the paying customer status
    const fetchPayingCustomerStatus = async () => {
      
      dispatch(setPayingCustomer(payingCustomer));
    };

    if (userId) {
      fetchPayingCustomerStatus();
    }
  }, 
  [dispatch, userId, payingCustomer]);

  const { lastJsonMessage } = useWebSocket(
    `${WS_URL}?userId=${userId}`,
    {
      onOpen: () => console.log('WebSocket connection established.'),
      onClose: () => console.log('WebSocket connection closed.'),
      onMessage: (event) => console.log('Event: ', event),
      shouldReconnect: () => true,
      heartbeat: {
        message: 'ping',
        returnMessage: 'pong',
        timeout: 5 * 60000,
        interval: 5 * 1000,
      },
    }
  );

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const payload = lastJsonMessage.data;
      console.log("lastJsonMessage: ", lastJsonMessage);
      dispatch(setLatestPayload(payload));

      if (payload.status === "generated") {
        dispatch(UpdateReport(payload));
        if (!!userId) {
          dispatch(FetchBackendReports({ userId: userId, status: 'generated', page: 1, pageSize: pageSize }));
        }
      } else if (payload.status === "processing") {
        dispatch(UpdateReport(payload));
        if (!!userId) {
          dispatch(FetchBackendReports({ userId: userId, status: 'processing', page: 1, pageSize: pageSize }));
        }
      } else if (payload.status === "completed" && !payload.report_fetched) {
        if (payload.error === "no_transcription_found") {
          toast.error(`Report# ${payload.row_number}: Transcription failed. Please try a different recording.`, { toastId: `toast-${payload.id}` });
        } else {
          toast.error(`Report# ${payload.row_number}: The report could not be created. Please try again.`, { toastId: `toast-${payload.id}` });
        }
        dispatch(UpdateReport(payload));
      }
    }
  }, [dispatch, userId, lastJsonMessage]);

  return (
    <SubscriptionProvider>
      <Layout>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/add-report' element={<AddReport />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/report' element={<Report />} />
          <Route path='/edit-report' element={<EditReport />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </SubscriptionProvider>
  );
};

export default App;
