import React, { useEffect, useRef, useState, useCallback } from 'react'
import Clipboard from '../images/clipboard.png'
import { useNavigate } from 'react-router-dom'
import NoReportFound from '../components/NoReportFound';
import Notification from '../components/Notification';
import StatusDropdown from '../components/StatusDropdown';
import Pagination from '../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { FetchReport, FetchBackendReports } from '../redux/ReportsApis';
import { selectAllReports, selectInProgressReports, selectProcessingReports, selecteCompletedReports, setSelectedReport, selectAllReportsLoading, selectProcessingReportsLoading, selectInProgressReportsLoading, selecteCompletedReportsLoading, selecteGeneratedReports, selectGeneratedReportsLoading } from '../redux/ReportsSlice';
import Button from '../components/ui/Button';
import TableCell from '../components/ui/TableCell';
import Loader from '../components/Loader';
import { MainCardWrapper } from '../components/ui/CardWrapper';
import closeIcon from '../images/closeIcon.png';
import closeCircle from '../images/x-circle.png';
import TextInput from '../components/ui/TextInput';
import { getuser, handleUpdateStatus } from '../utils/HelperFunctions';
import { FilterIcon, SearchIcon } from '../components/ui/SvgIcons';
import { pageSize } from '../utils/constantVariables';
import { setPayingCustomer, setServiceName, setTemplates, setTrialEndDate, setWordCount } from '../redux/UserSlice';
import { toast } from 'react-toastify';
const EmptyFilterMode = ({ reset, filters }) => {
  return (filters?.patient_name === '' && filters?.doctor_name === '' && filters.clinic_name === '' && filters.comment === '') || reset
}

const getInitialFilters = () => {
  const savedFilters = localStorage.getItem('filterValues');
  return (savedFilters && savedFilters !== 'undefined') ? JSON.parse(savedFilters) : {
    patient_name: '',
    doctor_name: '',
    clinic_name: '',
    comment: ''
  };
};

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

function countFiltersApplied(filterValues) {
  let filters = 0
  for (var key in filterValues) {
    if (filterValues[key] && filterValues[key] !== "") {
      filters++;
    }
  }
  return filters === 0 ? null : filters
}

function Reports() {

  const selectedTabRef = useRef(localStorage.getItem('status') || 'all');
  const [page, setPage] = useState(1);
  const [showNotification, setShowNotification] = useState(false)
  const reports = {
    'all': useSelector(selectAllReports),
    'processing': useSelector(selectProcessingReports),
    'generated': useSelector(selecteGeneratedReports),
    'in-progress': useSelector(selectInProgressReports),
    'completed': useSelector(selecteCompletedReports),
  }
  const loading = {
    'all': useSelector(selectAllReportsLoading),
    'processing': useSelector(selectProcessingReportsLoading),
    'generated': useSelector(selectGeneratedReportsLoading),
    'in-progress': useSelector(selectInProgressReportsLoading),
    'completed': useSelector(selecteCompletedReportsLoading),
  }

  const [activeFilters, setActiveFilters] = useState(getInitialFilters);
  const [filterValues, setFilterValues] = useState(getInitialFilters);
  const isModelOpen = useRef(false)
  const [modelOpenState, setModelOpenState] = useState(isModelOpen.current)
  const [searchUserName, setSearchUserName] = useState('');
  const [searchIconClicked, setSearchIconClicked] = useState(false)

  const userToken = localStorage.getItem('user_session')
  const userId = localStorage.getItem('user_id')

  useEffect(() => {
    (document.body.style.overflow = modelOpenState ? "hidden" : "unset")
  }, [modelOpenState]);

  const [tabs, setTabs] = useState([
    {
      name: 'All',
      active: true,
      value: 'all'
    },
    {
      name: 'Processing',
      active: false,
      value: 'processing'
    },
    {
      name: 'Generated',
      active: false,
      value: 'generated'
    },
    {
      name: 'In Progress',
      active: false,
      value: 'in-progress'
    },
    {
      name: 'Completed',
      active: false,
      value: 'completed'
    },
  ])
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId) {
      navigate('/')
    }
  }, [userId, navigate])

  const handleFilterSearch = useCallback(({ search, reset, resetSearchBar = false, searchUserName, dispatch, page }) => {
    const statuses = ["all", "processing", "in-progress", "completed", "generated"]
    const filters = getInitialFilters()
    if (!!localStorage.getItem('user_id')) {
      const userId = localStorage.getItem('user_id');
      if (!search && !resetSearchBar) {
        search = searchUserName
      }
      if (search && !EmptyFilterMode({ reset, filters })) {
        statuses.forEach(item => dispatch(FetchBackendReports({ userId, status: item, page, pageSize, search, filters })))
        setActiveFilters({
          ...filters,
        })
      } else if (search) {
        statuses.forEach(item => dispatch(FetchBackendReports({ userId, status: item, page, pageSize, search })))
      } else if (EmptyFilterMode({ reset, filters })) {
        statuses.forEach(item => dispatch(FetchBackendReports({ userId, status: item, page, pageSize })))
      } else {
        statuses.forEach(item => dispatch(FetchBackendReports({ userId, status: item, page, pageSize, filters })))
        setActiveFilters({
          ...filters,
        })
      }
    }
  }, [])

  useEffect(() => {
    handleFilterSearch({ search: searchUserName, dispatch, page })
    // eslint-disable-next-line 
  }, [page, dispatch]);

  useEffect(() => {
    try {
      const userId = localStorage.getItem('user_id')
      if (!userId || userId === 'undefined') {
        return
      }
      const getUserData = async () => {
        const UserData = await getuser({ user_id: userId })
        if (UserData) {
          dispatch(setWordCount(UserData.words))
          dispatch(setServiceName(UserData.service_name))
          dispatch(setPayingCustomer(UserData.paying_customer))
          dispatch(setTrialEndDate(UserData.trial_end_date))
          dispatch(setTemplates(UserData.templates))
        }
      }
      getUserData()
    } catch (error) {
      console.log("User Data not found");
      toast.error("Failed to fetch user details. Please try again.");
    }
  }, [userToken, dispatch])

  const handleTabChange = async (tabType) => {
    let updatedTabs = []
    tabs?.forEach((tab) => {
      if (tab.name === tabType) {
        localStorage.setItem('status', tab.value)
        selectedTabRef.current = tab.value
        updatedTabs.push({
          name: tab.name,
          active: true,
          value: tab.value
        })
      }
      else {
        updatedTabs.push({
          name: tab.name,
          active: false,
          value: tab.value
        })
      }
    })
    setTabs(updatedTabs)
    setPage(1)
  };

  const handleReportClicked = (report) => {
    if (report?.status === 'processing') {
      setShowNotification(true)
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
      return
    }
    else if (!report.report_fetched) {
      return
    }
    else {
      let updatedReport = { ...report }
      if (report && report.report) {
        updatedReport.report = updatedReport.report?.replace(/placeholder_name/g, report?.patient_name);
        updatedReport.report = updatedReport.report?.replace(/placeholder_doctor/g, report?.doctor_name);
        updatedReport.report = updatedReport.report?.replace(/placeholder_clinic/g, report?.clinic_name);
      }
      dispatch(setSelectedReport(updatedReport))
      navigate('/report')
    }
  }
  const handleRetry = async ({ id }) => {
    await handleUpdateStatus({ documentId: id, status: 'retry' })
    dispatch(FetchReport({ id }))
  }

  const getReportsComponent = () => {
    const selectedTabReports = reports[selectedTabRef.current]
    const totalCount = selectedTabReports?.totalCount || 0;
    const startRange = (page - 1) * pageSize + 1;
    const endRange = Math.min(page * pageSize, totalCount);

    if (selectedTabReports?.reports?.length > 0) {
      return (
        <div>
          {
            selectedTabReports?.reports?.map((report, index) => {
              return (
                <div key={report.id} onClick={() => { handleReportClicked(report) }} className={`${report.report_fetched ? "cursor-pointer" : "cursor-not-allowed"}`}>
                  <TableCell
                    record={report}
                    last={selectedTabReports?.reports.length - 1 === index}
                    handleRetry={handleRetry}
                  />
                </div>
              )
            })
          }
         {selectedTabReports?.totalCount > pageSize && (
           <div className={`flex flex-col sm:flex-row  justify-between items-center md:items-center ${selectedTabReports.totalCount > pageSize ? 'pt-[20px]' : 'mt-4'}`}>
           <div className="text-[#868C98] max-w-[50%] text-sm sm:items-center hidden sm:flex overflow-hidden">
                <p className=" mr-2 whitespace-nowrap overflow-hidden">
                  Showing {<span className='font-bold'>{startRange}</span>} - {<span className='font-bold'>{endRange}</span>} of {<span className='font-bold'>{totalCount}</span>} reports
                </p>
              </div>
              <Pagination total={selectedTabReports.totalCount} totalPages={Math.ceil(selectedTabReports?.totalCount / 50)} pageSize={pageSize} page={page} setPage={setPage} className="mt-2 sm:mt-0 flex justify-center" />            </div>
          )}
          {!(selectedTabReports?.totalCount > pageSize) && (
            <div className={`flex-col sm:flex-row  justify-between items-center hidden sm:flex md:items-center ${selectedTabReports.totalCount > pageSize ? 'pt-[20px]' : 'mt-4'}`}>
            <div className="text-[#868C98] max-w-[50%] text-sm sm:items-center hidden sm:flex overflow-hidden">
                 <p className=" mr-2 whitespace-nowrap overflow-hidden">
                   Showing {<span className='font-bold'>{startRange}</span>} - {<span className='font-bold'>{endRange}</span>} of {<span className='font-bold'>{totalCount}</span>} reports
                 </p>
               </div>
               </div>
          )

          }
        </div>
      )
    }
    else {
      return (
        <NoReportFound />
      )
    }
  }

  // eslint-disable-next-line 
  const debouncedHandleSearchUserName = useCallback(debounce(({ search }) => {
    handleFilterSearch({ search, dispatch, page: 1 })
    setPage(1)
  }, 500), []);

  const handleFilterInputChange = (e) => {
    const { name, value } = e.target;
    const nameMapping = {
      patientName: "patient_name",
      doctorName: "doctor_name",
      clinicName: "clinic_name",
      comment: "comment",
    }

    setFilterValues(prevState => ({
      ...prevState,
      [nameMapping[name]]: value
    }));

    console.log('current filter:', filterValues);
  };

  const handleModalToggle = () => {
    const isOpen = isModelOpen.current
    isModelOpen.current = !isOpen
    if (isOpen) {
      setFilterValues({
        ...activeFilters,
      })
    }
    setModelOpenState(!isOpen)
  };

  const handleCloseModel = () => {
    isModelOpen.current = false
    setModelOpenState(isModelOpen.current)
  };

  const handleSearchIconClicked = (clicked) => {
    setSearchIconClicked(clicked)
  }

  const resetFilter = () => {
    setSearchUserName('')
    let filters = {
      patient_name: '',
      doctor_name: '',
      clinic_name: '',
      comment: ''
    }
    setFilterValues(filters)
    setActiveFilters(filters)
    localStorage.removeItem('filterValues')
    handleFilterSearch({ search: '', reset: true, dispatch, page: 1 })
    setPage(1)
  };

  const handleSearchUserName = (e) => {
    const { value } = e.target;
    setSearchUserName(value);
    debouncedHandleSearchUserName({ search: value })
  };

  const handleCloseIcon = () => {
    setSearchUserName('')
    handleFilterSearch({ search: '', reset: false, resetSearchBar: true, searchUserName, dispatch, page: 1 })
    setPage(1)
  }
  const getTextInput = () => {
    return (
      <TextInput
        name="search_name"
        placeholder={'Search...'}
        value={searchUserName}
        onChange={handleSearchUserName}
        type='text'
        width="200px"
        useWidthOnMobile={false}
        preIcon={(
          <SearchIcon />
        )}
        {...(!searchUserName) ? {} : {
          postIcon: (
            <img src={closeCircle} alt="" height={16} width={16} className="cursor-pointer" onClick={handleCloseIcon} />
          )
        }}
      />
    )
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const patient_name = formData.get('patientName');
    const doctor_name = formData.get('doctorName');
    const clinic_name = formData.get('clinicName');
    const comment = formData.get('comment');
    isModelOpen.current = false
    setModelOpenState(isModelOpen.current)
    const filters = { patient_name, doctor_name, clinic_name, comment }
    setFilterValues(filters);
    localStorage.setItem('filterValues', JSON.stringify(filters))
    handleFilterSearch({ dispatch, page: 1 })
    setPage(1)
  }

  const getFilterModel = () => {
    return (
      <div onClick={e => { e.stopPropagation() }} className=" md:block bg-white flex flex-col pt-[28px] px-[28px] sm:rounded-[15px] w-full sm:w-[420px] sm:max-h-min sm:min-h-min" style={{ 'boxShadow': '-8px 0px 16px 0px #0000000A' }}>
        <div className='flex justify-between items-center sm:flex-none flex-initial'>
          <h1 className='text-lg font-medium font-SuisseIntl'>Filters</h1>
          <div className='flex items-center py-[12px]'>
            <img src={closeIcon} alt="close icon not found" className="cursor-pointer h-[12px]" onClick={handleModalToggle} />
          </div>
        </div>
        <div className="sm:flex-none flex-auto">
          <form onSubmit={handleFormSubmit} className='flex flex-col pb-0 h-full sm:justify-center'>
            <div className='flex flex-col gap-[20px] mt-[28px]'>
              <TextInput
                name={'patientName'}
                label={'Patient name'}
                placeholder={'Enter patient name'}
                type='text'
                value={filterValues.patient_name}
                onChange={handleFilterInputChange}
              />
              <TextInput
                name={'doctorName'}
                label={'Doctor name'}
                placeholder="Enter doctor name"
                type='text'
                value={filterValues.doctor_name}
                onChange={handleFilterInputChange}
              />
              <TextInput
                name={'clinicName'}
                label={'Clinic name'}
                placeholder="Enter clinic name"
                type='text'
                value={filterValues.clinic_name}
                onChange={handleFilterInputChange}
              />
              <TextInput
                textArea={true}
                name={'comment'}
                label={'Comment'}
                placeholder="Add a comment (optional)"
                type='text'
                value={filterValues.comment}
                onChange={handleFilterInputChange}
              />
            </div>
            <div className='py-[28px] flex justify-center gap-[12px]'>
              {(activeFilters.clinic_name || activeFilters.comment || activeFilters.doctor_name || activeFilters.patient_name) && (
                <Button
                  type='button'
                  variant='light'
                  onClick={resetFilter}
                >
                  Reset
                </Button>
              )}
              <Button
                type='submit'
              >
                Filter
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
  const count = countFiltersApplied(activeFilters)

  return (
    <div>
   <MainCardWrapper>
      <div className="hidden sm:flex justify-between flex-wrap items-center pb-[28px] border-b-[1px] border-[#E5E7EC]">
        <div className="flex">
          <div className="flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px] mr-2">
            <img width={'20px'} src={Clipboard} alt="clip board logo" />
          </div>
          <div className="ml-[16px] text-left">
            <h1 className="font-medium text-[16px]">Reports</h1>
            <p className="text-[#505050] font-SuisseIntlLight font-normal mt-[4px] text-[14px]">Track the status of your reports here</p>
          </div>
        </div>
        <div className="mt-[4px] sm:mt-0" onClick={() => { navigate('/add-report'); }}>
          <Button>
            <span className="text-[20px] mr-[8px]">+</span>
            <span className="text-[14px]">Add report</span>
          </Button>
        </div>
      </div>
      <div className="flex flex-col sm:hidden">
        <div className="flex flex-col text-center items-center">
          <div className="flex justify-center items-center rounded-full border min-w-[44px] w-[44px] h-[44px]">
            <img width={'20px'} src={Clipboard} alt="clip board logo" />
          </div>
          <h1 className="font-medium text-[16px] mt-[10px]">Reports</h1>
          <p className="text-[#505050] font-SuisseIntlLight font-normal mt-[4px] text-[14px]">Track the status of your reports here</p>
        </div>
        <div className="mt-[10px]" onClick={() => { navigate('/add-report'); }}>
          <Button>
            <span className="text-[20px] mr-[8px]">+</span>
            <span className="text-[14px]">Add report</span>
          </Button>
        </div>
      </div>
      <div>
        <div className="w-full border-[#E5E7EC] border-b-[1px] py-[28px]">
          {!searchIconClicked ? (
            <div className="flex flex-row justify-between items-center max-h-full">
              <>
                <div className="hidden sm:flex items-center">
                  <StatusDropdown tabs={tabs} selected={selectedTabRef.current} handler={handleTabChange} reports={true} showReportCount={true}/>
                </div>
                <div className="flex items-center sm:hidden w-full">
                  <StatusDropdown tabs={tabs} selected={selectedTabRef.current} handler={handleTabChange} reports={true} mobileView={true} showReportCount={true}/>
                </div>
              </>
              <div className="flex justify-end gap-2">
              <div className="hidden sm:block relative h-[42px] w-[209px]">
                  {getTextInput()}
                </div>
                <div>
                  <button className="flex ml-[10px] sm:ml-0 sm:hidden items-center gap-[10px] text-[14px] justify-start border-[#E5E7EC] text-[#525866] border-[1px] rounded-[10px] py-[10px] px-[12px] w-[44px] h-[42px]" onClick={() => { handleSearchIconClicked(true); }}>
                    <SearchIcon />
                  </button>
                </div>
                <div>
                  <button className="hidden sm:flex items-center gap-[10px] text-[14px] justify-start border-[#E5E7EC] text-[#525866] border-[1px] rounded-[10px] py-[10px] px-[16px] min-w-[102px] h-[42px] hover:border-[#CED0D5]" onClick={handleModalToggle}>
                    <div className="mt-[0.05rem]"><FilterIcon /></div>
                    <span className="mt-[0.2rem]">Filters</span>
                    { count ?
                      <span class="circle">
                        <span>{count}</span>
                      </span> : null
                    }
                  </button>
                  <button className="flex relative sm:hidden items-center gap-[10px] text-[14px] justify-start border-[#E5E7EC] text-[#525866] border-[1px] rounded-[10px] py-[10px] px-[12px] w-[44px] h-[42px]" onClick={handleModalToggle}>
                    <div>
                      { count ?
                        <span class="circle mobile right-[-0.45rem] top-[-0.45rem] absolute">
                          <span>{count}</span>
                        </span> : null
                      }
                      <FilterIcon />
                    </div>
                  </button>
                  {modelOpenState && (
                    <div>
                      <div className="hidden sm:block">
                        <div onClick={handleCloseModel} className="fixed top-0 left-0 w-full h-screen flex justify-center items-center z-50 bg-gray-800 bg-opacity-50">
                          {getFilterModel()}
                        </div>
                      </div>
                      <div className="sm:hidden top-0 left-0 h-[calc(100dvh)] w-screen fixed z-50 flex">
                        {getFilterModel()}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex">
              {getTextInput()}
              <button
                className="text-[#525866] ml-[16px] cursor-pointer text-sm font-normal"
                onClick={() => { handleSearchIconClicked(false); }}
              >Cancel</button>
            </div>
          )}
        </div>
        <div className="min-h-[448px] sm:min-w-full flex flex-col justify-center items-center">
          {loading[selectedTabRef.current] ? (
            <div className="w-full h-full min-h-[30vh] flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div className="w-full h-full flex-1 flex flex-col">
              {getReportsComponent()}
            </div>
          )}
        </div>
      </div>
      {showNotification && (
        <Notification text={"This report is being generated"} color={'bg-black'} />
      )}
    </MainCardWrapper>
    </div>
  )
}

export default Reports
