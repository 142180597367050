import React, { useEffect, useState } from 'react'

function CustomWeglotSwitcher() {

  const [currentLanguage, setCurrentLanguage] = useState('')

  const handleLanguageChange = (event) => {
    event.preventDefault()
    const selectedLanguage = event.target.value;
    // eslint-disable-next-line no-undef 
    Weglot.switchTo(selectedLanguage);
    setCurrentLanguage(selectedLanguage)
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // eslint-disable-next-line no-undef 
    setCurrentLanguage(Weglot.getCurrentLang())
  })
  /* eslint-disable react-hooks/exhaustive-deps */


  return (
    <div className="text-center wg-ignore">
        <div className="text-[#B7BECB] mt-[4px] mb-[24px] flex justify-center">
          <select
            id="weglot-switch"
            className="cursor-pointer hover:underline bg-transparent border-none outline-none"
            onChange={handleLanguageChange}
            value={currentLanguage}
            >
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="de">Deutsch</option>
            <option value="fr">Français</option>
          </select>
        </div>
      </div>
  )
}

export default CustomWeglotSwitcher
